@use 'styles/mixins' as *;

.Pagination {
  display: flex;
  gap: 4px;

  button {
    &:focus {
      position: relative;
      z-index: 300;
    }
  }

  .pag-button {
    @include size(32px);

    &-empty {
      @include flex-center();
      user-select: none;
    }
  }

  .arrow-button {
    &:disabled {
      background-color: transparent;
    }
  }
}
