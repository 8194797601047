.Modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0008;
  z-index: 2000;
  display: flex;
  align-items: center;

  animation: ModalOverlay-appear 0.5s ease-out;

  .Modal {
    animation: Modal-appear 0.1s ease-out;
  }

  &.position {
    &-start {
      justify-content: flex-start;
    }
    &-end {
      justify-content: flex-end;
    }
    &-center {
      justify-content: center;
    }
  }
}

@keyframes Modal-appear {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes ModalOverlay-appear {
  from {
    background-color: #0000;
  }
  to {
    background-color: #0008;
  }
}
