@use 'styles/colors' as *;
@use 'styles/variables' as *;

.InfoTooltip {
  &__trigger {
    height: 16px;
  }
}

.InfoTooltip__container {
  z-index: 1500;

  &__content {
    background-color: $secondary-D1;
    padding: 4px 6px;
    box-shadow: $shadow-dropdown;
    max-width: 238px;
    font-size: 12px;
  }

  &__arrow {
    svg {
      display: block;
    }
  }
}
