@use 'styles/colors' as *;
@use 'styles/mixins' as *;
@use 'styles/variables' as *;

dialog {
  margin: auto;
  background-color: $secondary-D3;
  border: none;
  box-shadow: $shadow-dropdown;
  color: $white;
  padding: 0;

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.modal {
  padding: 32px;
  background-color: $secondary-D3;
  min-width: 440px;

  &-header {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 12px;
  }

  &-form {
    @include flex-col(12px);
    &-control {
      @include flex-col(4px);

      &__input {
        width: 100% !important;

        & textarea {
          height: 300px;
        }
      }

      &__label {
        font-weight: 700;
      }

      &__error {
        color: $red-text;
      }
    }
  }

  p {
    max-width: 376px;
    color: $secondary-L3;
  }

  &-buttons {
    margin-top: 24px;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 1fr);
    gap: 24px;
  }
}
