@use 'styles/colors' as *;
@use 'styles/variables' as *;

.Tooltip {
  &__content {
    padding: 4px;
    font-size: 12px;
    background-color: $secondary-D1;
    box-shadow: $shadow-info;
    z-index: 3000;
    max-width: 80vw;
  }
}
