@use 'styles/colors' as *;
@use 'styles/mixins' as *;

.SimpleStatisticsInfo {
  display: grid;
  grid-template-columns: 32px auto;
  column-gap: 12px;

  &__icon {
    @include flex-center();
    grid-row: span 2;

    &__bg {
      @include size(32px);
      @include flex-center();

      &.green {
        background-color: $green-D1;
      }
      &.red {
        background-color: $red;
      }
    }
  }

  &__title {
    font-size: 14px;
    line-height: 22px;
    color: $secondary-L3;

    display: flex;
    align-items: center;
    gap: 2px;
  }

  &__value {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    font-family: 'Assistant';
  }
}
