@use 'styles/colors' as *;

.StaticTabs {
  display: flex;
  background-color: $secondary-D2;
  border-bottom: 2px solid $secondary-D1;

  .StaticTabs__Tab {
    text-decoration: none;
    margin-bottom: -2px;
    position: relative;
    border-bottom: 2px solid transparent;

    &:hover {
      border-color: $secondary-L1;
    }

    &.active {
      background-color: $secondary-D1;
      border-bottom: 2px solid $primary-L2;
      pointer-events: none;
    }
  }
}
