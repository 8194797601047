@use 'styles/colors' as *;
@use 'styles/mixins' as *;
@use 'styles/variables' as *;

.Filters {
  background-color: $secondary-D3;
  border: 1px solid $secondary-D1;
  height: auto;
  padding: 8px;
  position: relative;

  display: flex;
  justify-content: space-between;

  transition: all 0.1s;
  max-width: 100%;
  color: $secondary-L3;

  &.scrolled {
    box-shadow: 0 5px 15px #0008;
  }

  &__inputs {
    padding-right: 8px;
    display: grid;
    gap: 8px;
    grid-template-columns: 160px 160px min-content 160px min-content 160px;
    align-items: center;

    .SimpleDropdown,
    .MultiselectDropdown,
    .DateInput {
      width: 100%;
    }

    &__journal-content {
      display: flex;
      gap: 6px;
      align-items: center;
    }
  }

  &__buttons {
    padding-left: 8px;
    border-left: 1px solid $secondary-D1;
    display: flex;
    gap: 8px;

    button:not(.MenuButton) {
      width: 150px;
      padding: 0px;
    }
  }

  &__input-mid-item-divider {
    background-color: $secondary-D2;
    padding: 4px 16px;
    position: sticky;
    top: -5px;
    font-size: 12px;
    font-weight: 700;
    color: $secondary-L3;
    text-transform: uppercase;
  }
}

@media (max-width: 1260px) {
  .Filters {
    &__buttons {
      button:not(.important) {
        width: 40px;

        span.text {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 1104px) {
  .Filters {
    flex-direction: column;
    gap: 8px;

    &__buttons {
      padding-left: 0;
      border: none;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      button.important {
        width: 100%;
      }

      button:not(.important) {
        width: 100%;

        span.text {
          display: inline;
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .Filters {
    &__inputs {
      grid-template-columns: 1fr 1fr;
      gap: 8px;
      padding: 0;
    }
  }
}
