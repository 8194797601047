@use 'styles/colors' as *;
@use 'styles/mixins' as *;
@use 'styles/variables' as *;

.Login {
  .ModalLayout {
    min-width: 440px;
    padding: 32px 40px;
    box-shadow: $shadow-dropdown;

    .logo {
      display: block;
      margin: 0 auto;
      margin-bottom: 16px;
    }
  }
}
