@use 'styles/colors' as *;

$color-bg: #fff0;
$color-accent: rgba($secondary-L3, 0.2);
$color-blink: rgba($secondary, 0.2);

$accent-initial-padding: 100px;
$accent-size: 200px;
$bg-size: 1600px;
$animation-duration: 1s;

.SkeletonBlock {
  position: relative;
  width: 100%;
  height: 100%;

  background: linear-gradient(
    90deg,
    $color-bg $accent-initial-padding,
    $color-accent calc($accent-initial-padding + ($accent-size / 2)),
    $color-bg calc($accent-initial-padding + $accent-size),
    $color-bg $bg-size
  );
  background-size: $bg-size $bg-size;
  animation: skeleton-block-animation-movement $animation-duration infinite linear;

  &::after {
    position: absolute;
    inset: 0;
    content: '';
    background-color: $color-blink;
    animation: skeleton-block-animation-blinking $animation-duration infinite linear;
  }
}

@keyframes skeleton-block-animation-movement {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: $bg-size 0;
  }
}

@keyframes skeleton-block-animation-blinking {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  60%,
  100% {
    opacity: 0;
  }
}
