@use 'styles/colors' as *;

.WidgetEmptyState {
  padding: 32px;
  height: 75%;
  text-align: center;
  color: $secondary;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__title {
    font-size: 24px;
    margin-bottom: 8px;
  }
}
