@use 'styles/colors' as *;
@use 'styles/variables' as *;
@use 'styles/mixins' as *;

.Chart {
  background-color: $secondary-D3;
  border: 1px solid $secondary-D1;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  margin-bottom: 8px;
  color: $secondary-L3;
  @include flex-col(8px);

  &__label {
    padding: 0 12px;
    padding-top: 12px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;

    abbr {
      text-decoration: none;
    }
  }

  &__content {
    flex-grow: 1;

    .empty-state {
      @include size(100%);
      @include flex-center();
      font-size: 16px;
    }

    &_placeholder {
      margin: 12px;
      margin-top: 0px;
      flex-grow: 1;
      background: linear-gradient(90deg, #0000, $secondary 20%, #0000 30%), $secondary-D1;
      animation: skeleton-loading-animation 1s infinite linear;
      opacity: 0.3;
    }
  }
}

.tooltip {
  width: 150px;
  box-shadow: 0 8px 15px rgba($secondary-D4, 50%), 0 2px 5px rgba($secondary-D4, 50%);
  border: 1px solid $secondary-D1;

  .value {
    font-size: 24px;
    font-family: 'Assistant', sans-serif;
    font-weight: 700;
    color: $white;
    background-color: $secondary-D3;
    padding: 5px;
  }

  .date {
    color: $secondary-L3;
    padding: 5px;
    background-color: $secondary-D4;
    border-top: 1px solid $secondary-D1;

    & > span:first-child {
      color: $white;
      font-weight: 700;
    }
  }
}
