@use 'styles/colors' as *;
@use 'styles/mixins' as *;

.ManageJournalsPage {
  &__content {
    padding: 24px;
    padding-top: 16px;

    &__heading {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;

      .buttons {
        display: flex;
        gap: 8px;
        margin-left: auto;

        .SimpleDropdown {
          width: 190px;
          height: 24px;

          .Floating__trigger > button {
            height: 24px;
          }
        }

        .add-button {
          width: 160px;
        }
      }
    }
  }

  .Table {
    height: 512px;
  }

  .EmptyState {
    height: 80%;
    @include flex-col-center();
    justify-content: center;

    .primary {
      color: $secondary-L3;
      font-weight: bold;
      font-size: 24px;
    }
    .secondary {
      color: $secondary-L3;
    }
  }

  .Tabs {
    margin-top: -32px;
  }

  .heading {
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;
  }

  .table-wrapper {
    border: 1px solid $secondary-D1;
    min-height: 500px;
    background-color: $secondary-D4;
  }

  .TableCell.shared-with div {
    &.empty {
      opacity: 0.5;
    }

    display: flex;
    gap: 4px;
    align-items: center;
  }
}

@media (max-width: 780px) {
  .ManageJournalsPage {
    .Tabs {
      margin-top: 0;
    }

    &__content {
      &__heading {
        .buttons {
          margin-left: 0;
          justify-content: space-between;
          width: 100%;
          gap: 8px;

          .SimpleDropdown {
            width: 190px;
            height: 24px;

            .Floating__trigger > button {
              height: 24px;
            }
          }

          .add-button {
            width: 160px;
          }
        }
      }
    }

    .Table {
      height: 512px;
    }
  }
}
