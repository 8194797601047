@use 'styles/colors' as *;
@use 'styles/mixins' as *;

.ShareJournalDialog {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .ModalLayoutFormControl {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .hint {
    color: $secondary-L3;
    margin-top: -4px;
  }

  .Button.copy-link {
    width: 100%;
  }
}

.AccessControlBlock {
  @include flex-col(4px);

  .list {
    @include flex-col(4px);
  }
}

.AccessControlEntry {
  background-color: $secondary-D2;
  border: 1px solid $secondary-D1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 8px;
}
