@use '../common';
@use 'styles/colors' as *;
@use 'styles/mixins' as *;

.MultiselectDropdown {
  color: #fff;
  width: 150px;
}

.MultiselectDropdownItem {
  padding: 5px 12px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.MultiselectDropdownItemList {
  background-color: $secondary-D1;
  padding-top: 4px;
  max-height: 400px;
  min-height: 150px;
  display: flex;
  flex-direction: column;

  .search {
    padding: 4px 8px;
    input {
      width: 100%;
      height: 24px;
    }
  }

  .list {
    overflow-y: auto;
    flex-grow: 1;
  }

  .buttons {
    position: sticky;
    bottom: 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    padding: 8px;
    padding-bottom: 12px;
    background-color: $secondary-D1;
    border-top: 1px solid $secondary;

    button {
      height: 24px;
    }
  }
}
