@use 'styles/colors' as *;

.NotesTab {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .notes {
    background-color: $secondary-D3;
    padding: 8px;

    .notes-list {
      width: 800px;
      margin: auto;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .note-skeleton {
      height: 200px;
    }
  }
}
