@mixin flex($gap: 0px) {
  display: flex;
  gap: $gap;
}

@mixin flex-center($gap: 0px) {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $gap;
}

@mixin flex-col($gap: 0px) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

@mixin flex-col-center($gap: 0px) {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $gap;
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin text-3-dots() {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin pseudo-absolute($z-index: unset) {
  position: absolute;
  inset: 0;
  content: "";
  z-index: $z-index;
}
