@use 'styles/colors' as *;
@use 'styles/mixins' as *;

.ModalWindowLayout {
  background-color: $secondary-D3;
  max-height: 90vh;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
}

.ModalWindowLayoutHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: $secondary-D1;
  padding: 8px 16px;
  padding-right: 8px;
}

.ModalWindowLayoutContent {
  padding: 24px;
  flex-grow: 1;
  overflow-y: auto;

  &:not(:last-child) {
    padding-bottom: 0;
  }
}

.ModalWindowLayoutFooter {
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
  background-color: $secondary-D3;

  .Button {
    min-width: 150px;
  }

  &.wide {
    justify-content: space-between;
    width: 100%;
  }
}
