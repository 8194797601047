@use 'styles/colors' as *;
@use 'styles/variables' as *;

.PageLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  --paddings: 16px;
  --max-width: calc(#{$global-content-width-no-paddings} + (var(--paddings) * 2));
}

.PageLayoutCustomHeader {
  background-color: $secondary-D2;
  box-shadow: $shadow-header;
  height: 56px;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    max-width: var(--max-width);
    padding: 0 var(--paddings);
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.PageLayoutContent {
  flex-grow: 1;

  &__content {
    margin: 0 auto;
    max-width: var(--max-width);
    padding: 0 var(--paddings);
  }
}

.PageLayoutFooter {
  background-color: $secondary-D2;
  height: 24px;
  margin-top: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 850px) {
  .PageLayout {
    --paddings: 8px;
  }
}

@media (max-width: 640px) {
  .PageLayout {
    .PageLayoutCustomHeader {
      margin-bottom: 8px;
    }
    .PageLayoutContent {
      &__content {
        padding: 0;
      }
    }
    .PageLayoutFooter {
      margin-top: 32px;
      height: auto;
      text-align: center;
    }
  }
}
