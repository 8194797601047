@mixin solid($bg, $bg-hover, $bg-active, $bg-disable, $fg: $white) {
  background-color: $bg;
  color: $fg;
  user-select: none;

  &:hover {
    background-color: $bg-hover;
  }

  &:active {
    background-color: $bg-active;
  }

  &:disabled {
    color: $text-disable-light;
    background-color: $bg-disable;
  }
}

@mixin outline($border, $border-hover, $border-active) {
  border: 2px solid $border;

  &:hover {
    border-color: $border-hover;
  }

  &:active {
    border-color: $border-active;
  }
}

@mixin link($color, $color-active) {
  color: $color;

  &:hover,
  &:active {
    text-decoration: underline;
  }

  &:active {
    color: $color-active;
  }

  &:disabled {
    color: $text-disable-light;
  }
}
