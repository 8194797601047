@use 'styles/colors' as *;
@use 'styles/mixins' as *;

button {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.36px;
  box-sizing: border-box;
  font-family: 'Assistant', sans-serif;
  -webkit-tap-highlight-color: transparent;

  cursor: pointer;
}

a {
  color: $primary-L3;

  &:hover {
    color: $primary-L2;
  }
  &:active {
    color: $primary;
  }

  &:visited {
    color: $purple;

    &:hover {
      color: $purple-D1;
    }
    &:active {
      color: $primary;
    }
  }
}
