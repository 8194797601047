.TradeInfoGridFull {
  display: grid;
  grid-template-columns: repeat(3, minmax(184px, auto));
  column-gap: 24px;
  row-gap: 16px;

  .double-cell {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .TradeInfoGridCell {
    .label {
      margin-bottom: 4px;
    }
    .value {
      font-weight: 700;
    }
  }
}
