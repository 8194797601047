@use '../common';
@use 'styles/colors' as *;
@use 'styles/mixins' as *;

.SimpleDropdown {
  color: #fff;
  width: 160px;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.error {
    .DropdownTrigger {
      border: 1px solid $red-text;
    }
  }

  &__content {
    position: relative;
    max-height: 400px;
    overflow-y: auto;
    z-index: 2000;
    padding: 4px 0;

    button {
      justify-content: start !important;
      text-align: start !important;
      @include text-3-dots();
    }

    & > * {
      width: 100%;
    }
  }

  .Floating {
    // width: 100%;

    .Floating__trigger {
      // width: 100%;
    }
  }
}
