@use 'colors' as *;
@use 'mixins' as *;

@mixin general-input() {
  padding: 0 8px;
  color: #fff;
  background-color: $secondary-D2;
  border: 1px solid $secondary;
  transition: none;
  height: 32px;
  border-radius: 0px;
  font-family: 'Nunito Sans', sans-serif;

  &:hover {
    border-color: $secondary-L3;
    background-color: $secondary-D1;
  }

  &:focus {
    border-color: $primary;
    outline: none;
    transition: all 0.2s;
    background-color: $secondary-D4;
  }

  &::placeholder {
    color: $secondary-L3;
  }

  &:disabled {
    background-color: $secondary-D1;
    color: $text-disable-light;

    &:hover {
      border-color: $secondary;
    }
  }

  &.error {
    border-color: $red-text;

    &:hover {
      border-color: $red-text;
    }

    &:focus {
      border-color: $red-text;
    }
  }
}

.general-input {
  @include general-input();
}
