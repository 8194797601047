@use 'styles/colors' as *;
@use 'styles/mixins' as *;
@use 'styles/variables' as *;

.DateInput__content .react-calendar {
  position: relative;
  z-index: 200;
  border-right: 1px solid $secondary;
  padding: 8px;

  button {
    border-color: transparent;
    background-color: transparent;

    &:hover {
      background-color: $secondary;
    }

    &:active {
      background-color: $secondary-L1;
    }
  }

  &__navigation {
    display: flex;

    button {
      height: 32px;
    }

    &__label {
      pointer-events: none;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__arrow {
      height: 32px;
      width: 32px;
      color: $secondary-L3;
      font-size: 24px;
      padding-bottom: 4px;
    }

    &__prev2-button,
    &__next2-button {
      display: none;
    }
  }

  &__month-view {
    &__weekdays {
      color: $white;
      padding-bottom: 5px;
      padding-top: 5px;
      text-align: center;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      font-family: 'Assistant', sans-serif;

      abbr {
        text-decoration: none;
      }
    }
    &__days {
      box-sizing: border-box;

      &__day {
        color: $white;
        font-weight: 400;

        &--neighboringMonth {
          color: $secondary-L2;
        }
      }
    }
  }

  &__tile {
    padding: 0px;
    @include size(43px, 32px);
    position: relative;

    &--now {
      font-weight: 700;

      &::after {
        position: absolute;
        content: '';
        @include size(4px);
        background-color: $primary;
        border-radius: 50%;
        left: 50%;
        bottom: 0px;
        transform: translateX(-50%);
      }
    }

    &--active {
      background-color: $primary !important;
      font-weight: 700;
      color: $white;

      &:hover {
        background-color: $primary-L2;
      }
    }
  }
}
