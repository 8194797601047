@use 'styles/colors' as *;
@use 'styles/variables' as *;

.PageLayoutHeader {
  &__logo {
    display: flex;
    align-items: center;
    height: 32px;

    h1 {
      position: absolute;
      pointer-events: none;
      opacity: 0;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    gap: 16px;

    a {
      color: unset;
      text-decoration: none;
    }

    .divider {
      width: 1px;
      height: 32px;
      background-color: $secondary;
    }
  }

  &__SideMenu {
    background-color: $secondary-D3;
    height: 100vh;
    max-width: 100vw;
    width: 300px;

    &__header {
      background-color: $secondary-D1;
      display: flex;
      align-items: center;
      gap: 8px;
      height: 56px;
      padding: 0 8px;
    }

    &__content {
      padding: 16px;

      .auth {
        border-bottom: 1px solid $secondary-D1;
        margin-bottom: 16px;
        padding-bottom: 16px;

        .email {
          text-align: center;
        }

        .Button {
          width: 100%;
          height: 32px;
          margin-top: 16px;
        }
      }

      nav {
        display: flex;
        flex-direction: column;
        gap: 8px;

        a {
          text-decoration: none;
        }

        .Button {
          width: 100%;
          height: 40px;
        }
      }
    }
  }

  .mobile-only {
    display: none;
  }
  .desktop-only {
    display: flex;
  }
}

@media (max-width: 955px) {
  .PageLayoutHeader {
    .mobile-only {
      display: flex;
    }
    .desktop-only {
      display: none;
    }
  }
}
