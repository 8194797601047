@use 'styles/colors' as *;
@use 'styles/mixins' as *;
@use 'styles/forms' as *;
@use 'styles/variables' as *;

.DropdownTrigger {
  width: 100%;
  position: relative;
  padding-right: 20px;

  justify-content: space-between;

  &__arrow {
    &--flipped {
      transform: rotate(180deg);
    }
  }

  &__content {
    @include text-3-dots();
    flex-grow: 1;
    text-align: left;

    &.placeholder {
      color: $secondary-L3;
    }
  }
}

.DropdownItem {
  @include text-3-dots();
  height: unset;
  min-height: 32px;
  width: 100%;
  font-family: 'Nunito Sans', sans-serif !important;
  font-weight: 400 !important;

  &--selected {
    color: $primary-L3 !important;

    .DropdownItemText {
      color: $primary-L3;
    }
  }
}

.DropdownDivider {
  border-bottom: 1px solid $secondary;
  margin-bottom: 2px;
  padding-top: 2px;
}

.Floating {
  &__content {
    background-color: $secondary-D1;
    box-shadow: $shadow-dropdown;
    display: flex;
    flex-direction: column;

    &--elevated {
      z-index: 5000;
    }
  }
}

.DropdownItemText {
  padding: 5px 0;
  overflow-x: hidden;

  &__primary {
    @include text-3-dots();
  }

  &__secondary {
    color: $secondary-L3;
    @include text-3-dots();
  }
}

.DropdownActionItem {
  display: flex;
  gap: 8px;
  width: 100%;
}

.DropdownFooterSection {
  position: sticky;
  bottom: -4px;
  margin-bottom: -4px;
  padding-bottom: 4px;
  background-color: $secondary-D1;
}
