@use 'styles/colors' as *;
@use 'styles/mixins' as *;

.BarInfoSection {
  @include flex-col(23px);

  .info-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    .value {
      font-size: 16px;
      font-weight: 700;
    }

    .BarInfoSectionLabel {
      font-size: 14px;
      margin-bottom: 4px;
    }

    .big {
      grid-column: span 2;
      margin-bottom: -1px;

      .BarInfoSectionLabel {
        font-size: 14px;
        margin-bottom: -1px;
      }

      .value {
        font-family: 'Assistant';
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
      }
    }
  }
}

.Bar {
  height: 6px;
  background-color: $secondary-D1;
  display: flex;

  &__indicator {
    height: 100%;
    transition: all 0.2s;

    &--green {
      background-color: $green;
    }
    &--red {
      background-color: $red;
    }
  }
}

.BarInfoSectionLabel {
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 12px;
  color: $secondary-L3;

  &__label {
    display: flex;
    gap: 4px;
  }

  &__square {
    @include size(8px);
    background-color: $secondary-L3;

    &.red {
      background-color: $red;
    }
    &.green {
      background-color: $green;
    }
  }
}

///

.StatSection {
  @include flex-col(4px);

  &__label {
    color: $secondary-L3;
    line-height: 24px;
  }

  &__content {
    @include flex(8px);
    align-items: center;
    font-weight: 700;

    &__square {
      @include size(8px);

      &.red {
        background-color: $red;
      }
      &.green {
        background-color: $green;
      }
    }
  }
}
