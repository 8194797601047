@use 'styles/colors' as *;

.NoteItem {
  display: flex;
  flex-direction: column;
  gap: 8px;

  background-color: $secondary-D2;
  border: 1px solid $secondary;
  padding: 16px;
  cursor: pointer;

  &:hover {
    background-color: $secondary-D1;
  }

  &:active:has(.MediaRow:active) {
    background-color: $secondary-D2;
  }

  &:active {
    background-color: $secondary-D3;
  }

  &.draft {
    border-color: $red-text;
    position: relative;

    &::after {
      content: 'Draft';
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 4px 8px;
      background-color: $red-text;
      color: black;
      font-weight: 700;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  .creation-date {
    display: flex;
    gap: 4px;
  }
}
