@use 'styles/colors' as *;

.TabsButtons {
  border-bottom: 2px solid $secondary;
  display: flex;
  margin-bottom: 12px;

  .TabButton {
    width: 150px;
    padding: 0;
    background-color: $secondary-D2;
    border-bottom: 2px solid $secondary;
    margin-bottom: -2px;

    &:hover {
      border-color: $secondary-L2;
    }

    &.active {
      border-color: $primary-L3;
      background-color: $secondary-D1;

      &:hover {
        background-color: $secondary;
      }
    }
  }
}
