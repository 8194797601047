@use 'styles/colors' as *;

.CreateJournalNoteModal {
  width: 720px;

  .infobox {
    display: flex;
    gap: 8px;
    align-items: center;
    border: 1px solid $primary-D1;
    background-color: $primary-D4;
    padding: 8px;
    margin-bottom: 8px;
  }

  .ModalLayoutFormControl {
    width: 100%;
    .SimpleDropdown {
      width: 100%;
    }
  }
}
