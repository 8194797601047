@use 'styles/colors' as *;

.Menu {
  display: flex;
  flex-direction: column;
  padding: 4px 0;

  button,
  .MenuButton {
    width: 100%;
    justify-content: flex-start;
    height: 32px;
    font-family: 'Nunito Sans', sans-serif;
    text-align: left;
    padding-left: 8px;
    font-weight: 400;
    gap: 8px;

    &:hover {
      background-color: $secondary;
    }
  }

  .MenuDivider {
    border-top: 1px solid $secondary;
    padding-bottom: 2px;
    margin-top: 2px;
  }
}
