@use 'styles/colors' as *;

.MediaRow {
  display: flex;
  gap: 8px;

  .MediaEntry {
    width: 185px;
  }

  .more-available {
    width: 185px;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    background-color: $secondary-D1;
    color: $secondary-L3;
  }

  &.loading {
    height: 200px;
  }
  // align-items: center;
}
