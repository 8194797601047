@use 'styles/colors' as *;

.TableConfigModal {
  form {
    display: grid;
    gap: 4px;

    .form-control {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
}
