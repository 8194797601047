@use 'styles/colors' as *;
@use 'styles/mixins' as *;

.TradesTable {
  border: inherit;
  width: 100%;
  max-width: 100%;
  overflow-y: auto;
  min-height: 514px;

  .bold {
    font-weight: 700;
  }

  .MenuCell {
    .center {
      @include flex-center();

      .trade-menu-button {
        &:disabled {
          cursor: not-allowed;

          .Icon {
            opacity: 0.3;
          }
        }
      }
    }
  }

  .NoteCell {
    max-width: 130px;
    button {
      padding: 0;
      height: 100%;
      text-align: left;
      width: 130px;
      justify-content: space-between;
      @include text-3-dots();
    }

    abbr {
      text-decoration: none;
    }

    .text {
      display: block;
      width: 100%;
      height: 20px;
      line-height: 20px;
      @include text-3-dots;
    }
  }

  .TextDate {
    text-wrap: nowrap;
  }

  .EmptyState {
    background-color: $secondary-D4;
    @include flex-col-center();
    justify-content: center;
    color: $secondary-L3;
  }

  .bg-red {
    background-color: $red-L1;
  }

  .bg-green {
    background-color: $green-D1;
  }

  .text-red {
    color: $red-L1;
  }

  .no-wrap {
    white-space: nowrap;
  }
}
