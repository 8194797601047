@use 'styles/colors' as *;
@use 'styles/mixins' as *;
@use 'styles/forms' as *;

.input-error {
  border-color: $red-text !important;
}

.input-error-text {
  color: $red-text;
  font-size: 12px;
}

.general-input {
  @include general-input();
}

select {
  @include general-input();
  cursor: pointer;
}

textarea {
  @include general-input();
  height: unset;
  padding-top: 4px;
  padding-bottom: 4px;
}

input {
  &[type='text'],
  &[type='password'],
  &[type='number'],
  &[type='date'],
  &[type='time'],
  &[type='search'],
  &[type='email'] {
    @include general-input();
    color-scheme: dark;

    &.input-small {
      height: 24px;
    }

    &.input-large {
      height: 40px;
    }
  }

  &[type='number'] {
    &::-webkit-inner-spin-button {
      opacity: 0;
      display: none;
    }
  }

  &[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    margin: 0;
    position: relative;
    cursor: pointer;

    & + label {
      padding-left: 8px;
      cursor: pointer;
    }

    /* outer circle */
    &::before {
      box-sizing: border-box;
      display: block;
      border-radius: 50%;
      content: '';
      width: 16px;
      height: 16px;
      border: 2px solid $secondary-L2;
    }

    /* inner dot */
    &::after {
      position: absolute;
      content: '';
      left: 0px;
      top: 0px;
      @include size(14px);
      border-radius: 50%;
      transition: all 0.2s;
      background-color: transparent;
    }

    &:hover {
      &::before {
        border-color: $secondary-L3;
        background-color: $secondary;
      }
    }

    &:active {
      &::before {
        border-color: $primary;
      }
    }

    &:disabled {
      & + label {
        color: $text-disable-light;
      }
      &::before {
        border-color: $text-disable-dark;
      }

      &:checked {
        &::before {
          border-color: $text-disable-dark;
        }
        &::after {
          background-color: $text-disable-dark;
        }
      }

      &:hover {
        &::before {
          background-color: transparent;
        }
      }
    }

    &:checked {
      &::before {
        border-color: $primary;
      }

      &::after {
        background-color: $primary;
        left: 4px;
        top: 4px;
        @include size(8px);
      }
    }

    &:focus {
      outline: 2px solid #78bdfa;
    }
  }

  &[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    @include size(14px);
    position: relative;
    cursor: pointer;
    box-sizing: border-box;

    &::before {
      content: '';
      display: inline-block;
      box-sizing: border-box;
      @include size(14px);
      border: 2px solid $secondary-L2;
    }

    &::after {
      @include pseudo-absolute();
      opacity: 0;
      transition: all 0.1s;
      background: $primary
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M3 7.5L6.19014 10.3711C6.58283 10.7245 7.1827 10.7115 7.55969 10.3414L13 5' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
    }

    &:hover {
      &::before {
        border-color: $secondary-L3;
        background-color: $secondary;
      }
    }

    &:active {
      &::before {
        border-color: $primary;
        background-color: $secondary;
      }
    }

    &:checked {
      background-color: $primary;

      &::after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  &:-webkit-autofill {
    border: 1px solid $secondary;
    -webkit-text-fill-color: $white;
    -webkit-box-shadow: 0 0 0px 1000px $primary-D4 inset;

    &:hover {
      border-color: $secondary-L1;
      -webkit-box-shadow: 0 0 0px 1000px $primary-D3 inset;
    }

    &:focus {
      border-color: $primary;
    }
  }
}

.input-small {
  height: 24px;
}

.input-large {
  height: 40px;
}
