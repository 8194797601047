@use 'styles/colors' as *;

.MediaEntry {
  position: relative;
  width: 100%;
  aspect-ratio: 1/1;
  // height: 190px;
  border: 1px solid $secondary-D1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;

  img.thumbnail {
    max-width: 100%;
    max-height: 100%;
  }

  .thumbnail-skeleton {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: all 0.2s;
  }

  div.hover-card {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff1;
    opacity: 0;
    cursor: zoom-in;
  }

  button.delete {
    position: absolute;
    top: 4px;
    right: 4px;
    opacity: 0;
    z-index: 100;
  }

  &.loading {
    img {
      opacity: 0;
    }
    .thumbnail-skeleton {
      opacity: 1;
    }
  }

  &:hover {
    button.delete,
    div.hover-card {
      opacity: 1;
    }
  }
}

.MediaEntryFullViewModal {
  max-width: 90vw;
  max-height: 90vh;
  position: relative;

  img.full {
    max-width: 90vw;
    max-height: 90vh;
    position: relative;
  }

  img.thumbnail {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .Button.close-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
}
