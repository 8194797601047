@use 'styles/colors' as *;

.Chip {
  padding: 0 11px;
  border-radius: 11px;
  line-height: 24px;
  width: min-content;
  font-weight: 400;

  &.red {
    background-color: $red-L1;
  }

  &.green {
    background-color: $green-D1;
  }
}
