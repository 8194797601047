@use 'styles/colors' as *;

.StatisticsSection {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, 173px);
  gap: 8px;

  .Card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;

    .divider {
      border-bottom: 1px solid $secondary-D1;
      margin-top: 2px;
      margin-bottom: 2px;
    }
  }

  .BarInfoSection.compact {
    .info-grid {
      grid-template-columns: 1fr;
      gap: 8px;

      .big {
        grid-column: span 1;
      }

      div:not(.big) {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

@media (max-width: 480px) {
  .StatisticsSection {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
