@use 'styles/colors' as *;
@use 'styles/mixins' as *;

.Table {
  border: 1px solid $secondary-D1;
  background-color: $secondary-D4;
  overflow-y: auto;

  table {
    border-spacing: 0;
    width: 100%;

    .TableHeaderCell,
    .TableCell {
      padding-left: 16px;

      &:last-child {
        padding-right: 24px;
      }
    }
  }

  .TableHeader {
    background-color: $secondary-D3;
    position: sticky;
    top: 0px;
    z-index: 500;

    .TableHeaderCell {
      background-color: $secondary-D3;
      border-bottom: 1px solid $secondary-D1;
      text-align: left;
      height: 32px;
      white-space: nowrap;
      color: $secondary-L3;

      .SortingArrows {
        margin-left: -4px;
        &--active {
          fill: $secondary-L1;
        }
        &--asc {
          .SortingArrows__arrow--asc {
            fill: $primary;
          }
        }

        &--desc {
          .SortingArrows__arrow--desc {
            fill: $primary;
          }
        }
      }

      &__content {
        display: flex;
        gap: 4px;
        align-items: center;

        &.hoverable {
          user-select: none;
          cursor: pointer;

          &:hover {
            color: $white;

            .SortingArrows {
              fill: $secondary-L1;
            }
          }
          &:active {
            color: $secondary-L4;
          }
        }
      }
    }

    .TableEmptyHeaderCell {
      color: transparent;
      user-select: none;
    }
  }

  .TableBody {
    .TableRow {
      height: 48px;

      .TableCell {
        border-bottom: 1px solid $secondary-D1;
      }

      .MenuCell {
        & > * {
          margin-left: auto;
          @include size(24px);
        }
        .menu-trigger {
          padding: 0;
          @include size(24px);
        }
      }

      &.hoverable {
        cursor: pointer;

        &:hover {
          .TableCell {
            background-color: $secondary-D3;
          }
        }
      }
    }
  }
}
