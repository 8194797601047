@use 'styles/colors' as *;

.SelectJournalDialog {
  .list {
    background-color: $secondary-D4;

    button {
      width: 100%;
      text-align: left;
      justify-content: start;
    }
  }

  .input {
    width: 100%;
  }
}
