.NoteSection {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
  width: 650px;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
