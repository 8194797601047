@use 'styles/colors' as *;
@use 'styles/mixins' as *;
@use 'styles/variables' as *;
@use 'styles/forms' as *;

@import './react-datepicker.scss';

.DateInput {
  display: block;

  .Floating {
    width: 100%;
  }

  &__trigger {
    @include general-input();
    width: 100%;

    img {
      transition: all 0.1s;
    }

    &__value {
      transition: all 0.1s;

      .divider {
        color: $secondary-L1;
        margin: 0 2px;
      }
    }

    &__activated {
      img {
        transform: translateY(5px);
        opacity: 0;
      }

      .DateInput__trigger__value {
        transform: translateX(-10px);
      }
    }

    &__value {
      display: flex;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 400px;
    overflow-y: auto;
    max-height: 320px;

    &__calendar {
      padding: 16px;
    }

    &__time-picker {
      max-height: 100%;
      overflow-y: auto;
      min-width: 100px;

      button {
        width: 100%;
        color: $white;

        &.selected {
          background-color: $primary;
        }
      }
    }

    &__controls {
      display: flex;
      justify-content: space-between;
      padding: 8px 12px;
      border-top: 1px solid $secondary;

      button {
        height: 24px;
        padding: 0 12px;

        &.text-button {
          color: $white;
        }
      }
    }

    &__pickers-wrapper {
      display: flex;
      max-height: 100%;
      overflow-y: auto;
    }
  }
}
