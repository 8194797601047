@use 'styles/colors' as *;

.TextMoney {
  &.positive {
    color: $green;
  }

  &.negative {
    color: $red-text;
  }
}
