@use 'styles/colors' as *;

.NotesFilters {
  background-color: $secondary-D3;
  border: 1px solid $secondary-D1;
  padding: 8px;
  display: flex;
  justify-content: space-between;

  & > div {
    display: flex;
    gap: 8px;

    &.filters > * {
    }

    // &.buttons > * {
    //   width: 160px;
    // }
  }

  .SortingDropdownValueVisual {
    display: flex;
    gap: 4px;
    align-items: center;

    .meta {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  .sorting-dropdown {
    width: 240px;
  }
}
