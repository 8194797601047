@use 'styles/colors' as *;

.MediaUploadingStatus {
  .Loader {
    background-color: $secondary-D4;

    .text {
      display: flex;
      justify-content: space-between;
      padding: 4px 8px;
    }

    .progress {
      background-color: #0004;
      .progress-bar {
        height: 4px;
        background-color: $primary;
        transition: all 0.1s;
      }
    }
  }
}
