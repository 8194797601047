@use 'styles/colors' as *;

.WidgetsSection {
  display: grid;
  grid-template-columns: 452px 1fr 1fr;
  gap: 8px;

  .Widget {
    height: 316px;
    padding: 0;

    .header {
      color: $secondary-L3;
      padding: 8px;
    }
  }

  .span2 {
    grid-column: span 2;
  }

  .span3 {
    grid-column: span 3;
  }
}

.CommonTooltip {
  background-color: $secondary;
  padding: 8px;
  box-shadow: 0 3px 5px #0006;

  .value {
    font-weight: 700;
  }
}

@media (max-width: 1000px) {
  .WidgetsSection {
    grid-template-columns: 1fr 1fr;

    .span3 {
      grid-column: span 2;
    }

    .move-forward-mobile {
      order: -1;
    }
  }
}
