@use 'styles/colors' as *;

.TextDate {
  .date {
    &.bold {
      font-weight: 700;
    }
  }

  .time {
    color: $secondary-L3;
    font-weight: 400;
  }
}
