@mixin font-weight-regular {
  font-weight: 400;
}
@mixin font-weight-semi-bold {
  font-weight: 600;
}
@mixin font-weight-bold {
  font-weight: 700;
}

@mixin text-size-large() {
  font-size: 18px;
  line-height: 28px;
}
@mixin text-size-big() {
  font-size: 16px;
  line-height: 24px;
}
@mixin text-size-normal() {
  font-size: 14px;
  line-height: 160%;
}
@mixin text-size-description() {
  font-size: 12px;
  line-height: 16px;
}

@mixin text-generic-styles() {
  font-family: 'Nunito Sans', sans-serif;
  letter-spacing: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Assistant', sans-serif;
  font-weight: 500;
  letter-spacing: 0%;

  &.bold {
    @include font-weight-bold();
  }

  &.semi-bold {
    @include font-weight-semi-bold();
  }

  &.regular {
    @include font-weight-regular();
  }
}

input,
select {
  font-family: 'Nunito Sans', sans-serif;
}

h1 {
  font-size: 42px;
  line-height: 52px;

  &.slim {
    line-height: 100%;
  }
}

h2 {
  font-size: 32px;
  line-height: 40px;
}

h3 {
  font-size: 24px;
  line-height: 32px;
}

h4 {
  font-size: 20px;
  line-height: 28px;
}

/* ALL */
.text-large-regular,
.text-large-semibold,
.text-large-bold,
.text-big-regular,
.text-big-bold,
.text-normal-regular,
.text-normal-bold,
.text-description-regular,
.text-description-bold {
  @include text-generic-styles();
}

/* LARGE */
.text-large-regular,
.text-large-semibold,
.text-large-bold {
  @include text-size-large();
}
.text-large-regular {
  @include font-weight-regular();
}
.text-large-semibold {
  @include font-weight-semi-bold();
}
.text-large-bold {
  @include font-weight-bold();
}

/* BIG */
.text-big-regular,
.text-big-bold {
  @include text-size-big();
}
.text-big-regular {
  @include font-weight-regular();
}
.text-big-bold {
  @include font-weight-bold();
}

/* NORMAL */
.text-normal-regular,
.text-normal-bold {
  @include text-size-normal();
}
.text-normal-regular {
  @include font-weight-regular();
}
.text-normal-bold {
  @include font-weight-bold();
}

.text-description-regular,
.text-description-bold {
  @include text-size-description();
}
.text-description-regular {
  @include font-weight-regular();
}
.text-description-bold {
  @include font-weight-bold();
  letter-spacing: 2%;
}
