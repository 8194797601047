@use 'styles/colors' as *;
@use 'styles/mixins' as *;
@use 'styles/variables' as *;

.NotificationsList {
  position: fixed;
  z-index: 5000;
  right: 16px;
  bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .NotificationCard {
    display: flex;
    gap: 7px;
    position: relative;

    align-items: center;
    background-color: $secondary-D2;
    padding: 16px;
    width: 476px;
    box-shadow: $shadow-dropdown;

    &__icon {
      display: flex;
      align-items: center;
    }

    &__content {
      flex-grow: 1;
    }

    &__action {
      button {
        padding: 0 8px;
        height: auto;
      }
    }

    &__close-button {
      position: absolute;
      padding: 0px;
      @include size(16px);
      border-radius: 8px;
      right: -8px;
      top: -8px;
      font-size: 8px;
    }
  }
}
