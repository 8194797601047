@use 'styles/colors' as *;

.MetricsPage {
  .grid {
    gap: 24px;
  }
}

.MetricsCell {
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .params {
      columns: 3;

      .SimpleDropdown {
        width: 150px;
      }
    }
  }

  .chart-section {
    display: grid;
    grid-template-columns: auto 400px;
  }

  .value-grid {
    background-color: $secondary-D4;
    padding: 8px;

    & > div {
      display: grid;
      gap: 8px;
      grid-template-columns: 250px 50px;

      & > div:first-child {
        font-weight: bold;
        text-align: right;
      }
    }
  }

  .error {
    font-size: 18px;
    color: $red-text;
    padding: 16px;
  }
}
