@use 'styles/colors' as *;

.NoteMedia {
  .attachments-section__wrapper {
    position: relative;

    .attachments-section {
      padding: 16px;
      border: 2px dashed $secondary;
      // max-height: 420px;
      min-width: 500px;
      overflow-y: auto;

      .NoteMediaHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
        margin-bottom: 8px;
        color: $secondary-L3;

        .message {
          display: flex;
          gap: 4px;
          align-items: center;
        }

        .Button.browse-button {
          padding: 0;
        }

        .SortingDropdownContent {
          display: flex;
          gap: 4px;
        }
      }

      input[type='file'] {
        position: absolute;
        inset: 0;
        background-color: $secondary;
        opacity: 0;
        pointer-events: none;
      }

      .file-drop-overlay {
        position: absolute;
        inset: 0;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba($secondary-D2, 0.9);
        color: $secondary-L2;
        font-size: 24px;
        pointer-events: none;
        border: 3px dashed $primary;
        opacity: 0;
        backdrop-filter: blur(8px);
      }

      &.dragged-over {
        input[type='file'] {
          pointer-events: all;
        }

        .file-drop-overlay {
          opacity: 1;
        }
      }

      .empty-state {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $secondary-L3;
        margin-bottom: 16px;
        text-align: center;
      }

      .MediaGrid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 8px;
        width: 100%;
        justify-content: center;
        margin: 0 auto;

        &:empty {
          grid-template-columns: 1fr;
        }
      }

      .loaders {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-bottom: 16px;
      }
    }
  }
}
