@use 'styles/colors' as *;
@use 'styles/mixins' as *;

.TradesTableSection {
  width: 100%;
  font-size: 14px;
  overflow-y: auto;
  @include flex-col(8px);

  .header {
    color: $secondary-L3;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
  }

  &.loading {
    background-color: $secondary;
    min-height: 607px;

    background: linear-gradient(90deg, $secondary-D3, $secondary-D2 10%, $secondary-D3 40%),
      $secondary-D3;
    border: 1px solid $secondary;
    animation: skeleton-loading-animation 0.8s infinite linear;
  }

  .buttons {
    display: flex;
    gap: 4px;
  }
}
