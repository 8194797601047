@use 'styles/colors' as *;
@use 'styles/variables' as *;
@use 'styles/mixins' as *;
@use 'styles/forms' as *;
@use 'styles/fonts' as *;

@import './components/buttons.scss';
@import './components/inputs.scss';
@import './components/dialogs.scss';

html,
body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  background-color: $secondary-D4;
  color: $white;
  font-size: 14px;
  scrollbar-color: $secondary $secondary-D3;
  min-width: 700px;
}

* {
  box-sizing: border-box;
  margin: 0;
}

a {
  color: $primary-L2;
  &:hover {
    color: $primary-L3;
  }
}

.skeleton-loading {
  background-size: 100%;
  background: linear-gradient(90deg, #0000, rgba($secondary, 30%) 20%, #0000 40%);
  animation: skeleton-loading-animation 0.5s infinite linear;
}

.card-page {
  @include flex-center();
  min-height: 100vh;
}

@keyframes skeleton-loading-animation {
  0% {
    background-position: 0px 0%;
  }
  100% {
    background-position: 1200px 0%;
  }
}
